import Script from 'next/script';
import React from 'react';
const GOOGLE_ANALYTICS_ID = 'G-JF55G82FNT';
const SCRIPT_ELEMENT_ID = 'g-tag-analytics';

export function GoogleAnalytics() {
  return (
    <>
      <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`} />

      <Script id={SCRIPT_ELEMENT_ID} strategy="lazyOnload">
        {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GOOGLE_ANALYTICS_ID}', {
              page_path: window.location.pathname,
              });
          `}
      </Script>
    </>
  );
}
