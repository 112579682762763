import 'styles/globals.css';
import { ReactLenis } from '@studio-freight/react-lenis';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import React from 'react';

import { GoogleAnalytics } from '@/components/GoogleAnalytics';
import TranscendSnippet from '@/components/TranscendSnippet';
import useRouterProgressBar from '@/hooks/useRouterProgressBar';
import HydraLenis from '@/hydra/next/HydraLenis';
import { getPlayground } from '@/hydra/next/HydraSnippet';
import { useInterceptNextDataHref } from '@/utilities/useInterceptNextDataHref';
import { useWebVitals } from '@/utilities/useWebVitals';

export default function App({ Component, pageProps, router }: AppProps) {
  useWebVitals();
  useRouterProgressBar();

  // https://github.com/vercel/next.js/discussions/25681#discussioncomment-2026813
  // TODO (jackyang) consolidate this prefix logic more
  useInterceptNextDataHref({
    router,
    pathPrefix: '_marketing_assets',
  });

  if (getPlayground()) {
    // Hydra playgrounds, e.g. http://localhost:3000/?p=PlaygroundName,
    // are supported on local dev only, and take over the entire page.
    // Because SSG must render the home page on such urls (the querystring
    // is ignored during SSG), this causes a harmless hydration error that
    // can be ignored because getPlayground() *always* returns false in
    // production.
    return null;
  }
  return (
    <ReactLenis root autoRaf={false}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
        {/* Favicons */}
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://c5.patreon.com/external/favicon/rebrand/favicon-32.png?v=af5597c2ef"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://c5.patreon.com/external/favicon/rebrand/favicon-16.png?v=af5597c2ef"
        />
        <link rel="manifest" href="https://c5.patreon.com/external/favicon/rebrand/manifest.json?v=af5597c2ef" />
        <link rel="shortcut icon" href="https://c5.patreon.com/external/favicon/rebrand/favicon.ico?v=af5597c2ef" />
      </Head>
      <HydraLenis />
      <Script strategy="lazyOnload" src="https://jobs.ashbyhq.com/patreon/embed" />
      <TranscendSnippet />
      <GoogleAnalytics />
      <Component {...pageProps} />
    </ReactLenis>
  );
}
